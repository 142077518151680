import { createRouter } from 'vue-router'
import TemplateIndex from './template/TemplateIndex.vue'
import TemplateShow from './template/TemplateShow.vue'
import AppSettings from './template/AppSettings'

const routes = [
  {
    path: '/',
    name: 'template.index',
    component: TemplateIndex,
  },
  {
    path: '/settings',
    name: 'settings.index',
    component: AppSettings,
  },
  {
    path: '/template/:id',
    name: 'template.show',
    component: TemplateShow,
  }
]

export default function (history) {
  return createRouter({
    history,
    routes
  })
}
