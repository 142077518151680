import { createApp } from 'vue'
import App from './App.vue'

import store from './lib/store'
import router from './lib/router'

import "bootstrap/dist/css/bootstrap.min.css"

const app = createApp(App)

app.use(router)
app.use(store)
app.mount('#app');
