import moment from 'moment'
import {defineStore} from 'pinia'
import TemplateService from '@/services/TemplateService';
import OrderLineService from '@/services/OrderLineService';
import calculateBusinessDays from '../lib/calculateBusinessDays';

const defaultTemplate = {
    0: {
        id: 0,
        name: 'Other',
        groups: [],
        products: [],
        hasHighPriorityOrder: false,
        hasHighestPriorityOrder: false,
        hasExceededShipmentDate: false,
        oldestOrderLineDate: null,
        orderLines: [],
    }
}

const staticTemplates = [4, 5, 7, 8];

export const useTemplateStore = defineStore('templates', {
    state: () => ({
        templates: {},
        fetching: false,
        updating: false,
    }),

    getters: {
        getTemplates(state) {
            return state.templates;
        },
        isEmpty(state) {
            return Object.keys(state.templates).length === 0;
        },
        isFetching(state) {
            return state.fetching
        },
    },

    actions: {
        async fetch() {
            if (this.fetching) {
                console.log('[templateStore] - fetch() - already started');
                return;
            }

            this.fetching = true;
            console.log('[templateStore] - fetch() - has started');

            TemplateService.all().then(response => {

                console.log('[templateStore] - fetch() - received templates');

                // Receive templates
                let templates = {};
                response.data.forEach(template => {
                    template.hasHighPriorityOrder = false;
                    template.hasHighestPriorityOrder = false;
                    template.hasExceededShipmentDate = false;
                    template.oldestOrderLineDate = null;
                    template.orderLines = [];
                    templates[template.id] = template;
                });
                templates = {...templates, ...defaultTemplate}

                templates[0].oldestOrderLineDate = null;
                templates[0].orderLines = [];

                // Receive orders
                OrderLineService.all(window.localStorage.getItem('fetch_status')).then(response => {

                    console.log('[templateStore] - fetch() - received order lines');

                    response.data.forEach(orderLine => {

                        // Get template id for order line, if not set then set to 0 (other)
                        let templateId = orderLine.product.process_group_id || orderLine.product.group.process_group_id;
                        if (templateId === null) {
                            templateId = 0;
                        }

                        // Set oldest order date
                        const orderLineDate = moment(orderLine.order.created_at);
                        const oldestOrderLineDate = templates[templateId].oldestOrderLineDate;
                        if (oldestOrderLineDate === null || orderLineDate.isBefore(oldestOrderLineDate)) {
                            templates[templateId].oldestOrderLineDate = orderLineDate;
                        }

                        // Check if there is an order with high priority
                        if (orderLine.order.priority === 'high') {
                            templates[templateId].hasHighPriorityOrder = true;
                            orderLine.hasHighPriorityOrder = true;
                        }

                        // Check if there is an order with highest priority
                        if (orderLine.order.priority === 'highest') {
                            templates[templateId].hasHighestPriorityOrder = true;
                            orderLine.hasHighestPriorityOrder = true;
                        }

                        // Check if there is an order with exceeded shipment date
                        const days = calculateBusinessDays(orderLineDate, moment());
                        let dayLimit = 3;
                        if (staticTemplates.includes(templateId)) {
                            dayLimit = 5;
                        }
                        if (days > dayLimit) {
                            templates[templateId].hasExceededShipmentDate = true;
                            orderLine.hasExceededShipmentDate = true;
                        }

                        templates[templateId].orderLines.push(orderLine);
                    });

                    this.templates = templates;
                    this.fetching  = false;
                    console.log('[templateStore] - fetch() - completed');

                }).catch(e => {
                    this.exception(e)
                });
            }).catch(e => {
                this.exception(e)
            });
        },

        async updateBulk(ids, fields) {
            if (this.updating) {
                console.log('[templateStore] - updateBulk() - already started');
                return;
            }

            this.updating = true;
            console.log('[templateStore] - updateBulk() - has started');

            OrderLineService.updateBulk(ids, fields).then(() => {

                this.updating = false;
                console.log('[templateStore] - updateBulk() - completed');

                this.fetch();

            }).catch(e => {
                this.exception(e)
            })
        },

        exception(e) {
            console.error(e)
            this.templates = defaultTemplate;
            this.fetching = false;
            this.updating = true;
        },

        getTemplate(id) {
            return this.templates[id];
        },
    },
});