<template>
  <div class="row" v-if="template">
    <div class="col-12 mt-2 mb-4">
      <div class="d-flex justify-content-between">
        <h2 class="text-primary text-start m-0">{{ template.name }}</h2>
        <router-link :to="{name: 'template.index'}" class="d-flex align-items-center btn btn-primary"><strong>Back</strong></router-link>
      </div>


      <table class="table table-striped my-4">
        <thead class="bg-secondary bg-opacity-75">
          <th class="py-2">SKU</th>
          <th class="py-2 text-start">Product</th>
          <th class="py-2">Quantity</th>
          <th class="py-2">Branding</th>
          <th class="py-2">Date</th>
        </thead>
        <tbody>
          <template v-if="template.orderLines.length">
            <tr v-for="orderLine in template.orderLines" :key="orderLine.media.uuid" :class="{
              'bg-warning': orderLine.hasHighPriorityOrder && !orderLine.hasExceededShipmentDate && !orderLine.hasHighestPriorityOrder,
              'bg-danger': orderLine.hasExceededShipmentDate || orderLine.hasHighestPriorityOrder,
            }">
              <td :class="{'text-white': orderLine.hasExceededShipmentDate}">{{ orderLine.product.sku }}</td>
              <td class="text-start" :class="{'text-white': orderLine.hasExceededShipmentDate}">{{ orderLine.product.description }}</td>
              <td :class="{'text-white': orderLine.hasExceededShipmentDate}">{{ orderLine.quantity }}</td>
              <td :class="{'text-white': orderLine.hasExceededShipmentDate}">{{ orderLine.order.external_branding }}</td>
              <td :class="{'text-white': orderLine.hasExceededShipmentDate}">{{ moment(orderLine.order.created_at) }}</td>
            </tr>
          </template>
          <tr v-else><td colspan="5" class="text-start p-3">No order lines found.</td></tr>
        </tbody>
      </table>

      <div class="d-flex justify-content-end" v-if="template.orderLines.length">
        <button class="btn btn-primary" @click="update"><strong>Clear</strong></button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { useTemplateStore } from "@/stores/templateStore";

export default {
  setup() {
    const store = useTemplateStore();

    return {
      store,

      interval: null,
    }
  },

  computed: {
      template () {
        return this.store.getTemplate(this.$route.params.id)
      },
      moment () {
        return (value) => {
          return moment(value).format('YYYY-MM-DD H:mm:ss')
        }
      }
  },

  created() {
    this.store.fetch();
    this.interval = setInterval(() => this.store.fetch(), 5000);
  },

  beforeUnmount() {
    clearInterval(this.interval);
  },

  methods: {
    update() {
      const ids = Object.values(this.template.orderLines).map(orderLine => orderLine.id);
      this.store.updateBulk(ids, {
        'status': window.localStorage.getItem('update_status'),
      })
    }
  }
}
</script>
