<template>
  <div class="col-12 mt-2 mb-4">
    <div class="d-flex justify-content-between">
      <h2 class="text-primary text-start m-0">Settings</h2>
      <router-link :to="{name: 'template.index'}" class="d-flex align-items-center btn btn-primary">
        <strong>Back</strong></router-link>
    </div>


    <div class="form-group mt-4">
      <label class="d-block text-start">API Token</label>
      <input class="form-control" v-model="token">
    </div>

    <div class="form-group mt-4">
      <label class="d-block text-start">Fetch status</label>
      <input class="form-control" v-model="fetchStatus">
    </div>

    <div class="form-group mt-4">
      <label class="d-block text-start">Update status</label>
      <input class="form-control" v-model="updateStatus">
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      token: window.localStorage.getItem('api_token'),
      fetchStatus: window.localStorage.getItem('fetch_status') || 'printed',
      updateStatus: window.localStorage.getItem('update_status') || 'processing',
    }
  },

  created() {
    window.localStorage.setItem('fetch_status', this.fetchStatus);
    window.localStorage.setItem('update_status', this.updateStatus);
  },

  watch: {
    token(value) {
      window.localStorage.setItem('api_token', value);
    },
    fetchStatus(value) {
      window.localStorage.setItem('fetch_status', value);
    },
    updateStatus(value) {
      window.localStorage.setItem('update_status', value);
    },
  }
}
</script>
