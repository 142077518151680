<template>
  <div class="row template-list">
    <div class="col-12 d-flex justify-content-between align-items-center mt-2 mb-4">
      <h2 class="my-0">
        <span v-if="fetchStatus === 'printed'">Snijden</span>
        <span v-if="fetchStatus === 'processing'">Produceren</span>
      </h2>
<!--      <span class="d-inline-block me-3"><router-link :to="{ name: 'settings.index' }">settings</router-link></span>-->
    </div>

    <div class="col-6" v-for="template in sortedTemplates" :key="template.id">
      <router-link :to="{ name: 'template.show', params: { id: template.id }}">
        <div class="p-3 rounded text-start mb-3" :class="{
              'bg-primary bg-opacity-10': !template.hasHighPriorityOrder && !template.hasExceededShipmentDate,
              'bg-warning': template.hasHighPriorityOrder && !template.hasExceededShipmentDate && !template.hasHighestPriorityOrder,
              'bg-danger text-white':template.hasExceededShipmentDate || template.hasHighestPriorityOrder,
           }">
          <div class="d-flex justify-content-between">
            <strong class="d-block text-primary mb-2" :class="{
                'text-white': template.hasExceededShipmentDate,
                'text-body': template.hasHighPriorityOrder && !template.hasExceededShipmentDate,
              }">
              {{ template.name }}
            </strong>
            <strong v-if="template.hasHighPriorityOrder">High priority</strong>
            <strong v-if="template.hasHighestPriorityOrder"><u>Highest priority</u></strong>
          </div>
          <span class="d-block">Orders lines: <strong>{{ template.orderLines.length }}</strong></span>
          <span class="d-block">Oldest: <strong v-if="template.oldestOrderLineDate">{{ template.oldestOrderLineDate.format('YYYY-MM-DD') }}</strong><span v-else>-</span></span>
        </div>
      </router-link>
    </div>

    <div class="static container bg-white pt-3">
      <div class="row">
        <div class="col-3" v-for="id in staticTemplates" :key="id">
          <router-link :to="{ name: 'template.show', params: { id: store.getTemplate(id).id }}">
            <div class="p-3 rounded text-start mb-3" :class="{
              'bg-warning': store.getTemplate(id).hasHighPriorityOrder && !store.getTemplate(id).hasExceededShipmentDate && !store.getTemplate(id).hasHighestPriorityOrder,
              'bg-danger text-white': store.getTemplate(id).hasExceededShipmentDate || store.getTemplate(id).hasHighestPriorityOrder,
              'bg-primary text-white': store.getTemplate(id).orderLines.length > 0 && store.getTemplate(id).hasHighPriorityOrder === false && store.getTemplate(id).hasExceededShipmentDate === false,
              'bg-secondary text-white opacity-50': store.getTemplate(id).orderLines.length === 0 && store.getTemplate(id).hasHighPriorityOrder === false && store.getTemplate(id).hasExceededShipmentDate === false,
            }">
              <strong class="d-block mb-2" style="font-size: .875rem;">{{ store.getTemplate(id).name }}</strong>
              <span class="d-block opacity-75" style="font-size: .875rem;">Orders lines: <strong>{{ store.getTemplate(id).orderLines.length }}</strong></span>
              <span class="d-block opacity-75" style="font-size: .875rem;">Oldest: <strong v-if="store.getTemplate(id).oldestOrderLineDate">{{ store.getTemplate(id).oldestOrderLineDate.format('YYYY-MM-DD') }}</strong><span v-else>-</span></span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useTemplateStore } from "@/stores/templateStore";

export default {
  setup() {
    const store = useTemplateStore();

    return {
      store,

      interval: null,

      staticTemplates: [4, 5, 7, 8],
    }
  },

  data() {
    return {
      fetchStatus: window.localStorage.getItem('fetch_status'),
    }
  },

  created() {
    this.store.fetch();
    this.interval = setInterval(() => this.store.fetch(), 5000);
  },

  beforeUnmount() {
    clearInterval(this.interval);
  },

  computed: {
    filteredTemplates() {
      const templates = this.store.getTemplates;
      if (templates.length === 0) {
        return [];
      }

      return Object.values(templates).filter(template => {
        return template.orderLines.length > 0 && !this.staticTemplates.includes(template.id);
      });
    },
    sortedTemplates() {
      return Object.values(this.filteredTemplates).sort((a, b) => {
        if (a.oldestOrderLineDate === b.oldestOrderLineDate) {
          return 0
        }

        if (a.oldestOrderLineDate === null) {
          return 1
        }

        if (b.oldestOrderLineDate === null) {
          return -1
        }

        if (a.oldestOrderLineDate.isBefore(b.oldestOrderLineDate)) {
          return -1;
        }

        if (a.oldestOrderLineDate.isAfter(b.oldestOrderLineDate)) {
          return 1;
        }

        return 0;
      });
    }
  },

  methods: {

  }
}
</script>
<style>
  .template-list {
    padding-bottom: 200px;
  }

  .static {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }
</style>