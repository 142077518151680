import axios from '@/lib/axios'

class OrderLineService {
    all(status = '') {
        return axios.get('/order-line?status=' + status);
    }

    updateBulk(ids, fields) {
        const data = {
            ...fields,
            ids,
        }

        return axios.put('/order-line-bulk', data);
    }
}

export default new OrderLineService();