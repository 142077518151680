<template>
  <section class="container-fluid py-3">
    <router-view/>
  </section>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

a:not(.btn) {
  color: inherit !important;
  text-decoration: none !important;
}
</style>
